.collapsed > .when-opened,
:not(.collapsed) > .when-closed {
  display: none;
}
.cursor {
  cursor: pointer;
}
.fa {
  font-weight: 600;
}
/*# sourceMappingURL=src/components/shared/CoolapseCard.css.map */
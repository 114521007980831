























@import '~@/styles/variables'
.collapsed > .when-opened, :not(.collapsed) > .when-closed
  display none

.cursor
  cursor pointer
.fa
  font-weight 600

